import { template as template_ac3412d5a83f4bcabe25753692da67d2 } from "@ember/template-compiler";
const FKText = template_ac3412d5a83f4bcabe25753692da67d2(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
